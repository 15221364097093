// Here you can add other styles
.pointer {
    &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 21, 0.075);
    }
}
.form-control:disabled,
.custom-select:disabled {
    background-color: whitesmoke;
    border-color: whitesmoke;
    color: #303c54;
    opacity: 1;
}
.list-group {
    .list-files {
        padding:0;
        span, .close {
            display: none;
            position: absolute;
            right:10px;
            top:7px;
            cursor: pointer;
        }
        &:hover {
            background-color:#eee;
            span, .close {
                display: block;
            }
        }
        .btn {
            padding: 0.315rem 0.75rem;
        }
        &.more-space {
            .btn {
                padding: 0.75rem 1.25rem;
            }
            &:hover {
                .close {
                    opacity: 0.7;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.upload-list {
    padding: 0.54rem 1.25rem;
}
.comment-box {
    .close {
        opacity: 0;
    }
    &:hover {
        .close {
            opacity: 0.3;
        }
    }
    .close {
        &:hover {
            opacity: 1;
        }
    }
}